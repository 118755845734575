<template>
    <div>
        <hb-modal size="medium" :title="formattedWorkflowName" v-model="dialog" confirmation @close="$emit('close')">
            <template v-slot:content>
                <div v-if="selectedAutomationType.name == AUTOMATION.DELINQUENCY_AUTOMATION" class="py-4 px-6">
                    Disabling a workflow will <strong>pause all delinquencies</strong> using that automation and missed payments
                    will <strong>not initiate new delinquencies</strong>. 
                    <br/><br/>
                    Reactivating will <strong>resume paused delinquencies</strong> but delinquent tenants without an active delinquency
                    will <strong>not become delinquent</strong> until their next invoice.
                </div>
                <div v-if="selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION" class="py-4 px-6">
                    Turning off the pro plan will revert to utilizing the basic plan workflows. Pro plan workflows are all saved and can still be viewed.           
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="$emit('ok')" :disabled="disabled" :loading="disabled">Turn {{
                    !!workflow.assigned == true ? 'off' : 'on' }} Automation</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import AUTOMATION from '@/constants/automation.js';
import ADDRESS from '@/constants/address.js';
import { countryStateCityMixin } from '@/mixins/countryStateCityMixin.js';
import { mapGetters } from "vuex";
export default {
    name: 'WorkflowToggle',
    data() {
        return {};
    },
    mixins: [countryStateCityMixin],
    created() {
        this.AUTOMATION = AUTOMATION;
        this.setCountiesAndStates();
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        formattedWorkflowName() {
            return this.workflow.full_path ? this.formatWorkflowName(this.workflow.full_path) : "Warning";
        },
        ...mapGetters({
            selectedAutomationType:'automationStore/getSelectedAutomationType'
        })
    },
    props: {
        workflow:
        {
            type: Object,
            default() {
                return {};
            }
        },
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        selectedAutomationType: {
            type: Object,
            default: () => ({})
        },
        template: {
            type: String,
            default() {
                return 'corporate';
            }
        },
    },
    methods: {
        setCountiesAndStates() {
            this.setCountriesListEvent();
            this.setStatesListEvent(ADDRESS.DEFAULT_COUNTRY);
        },
        formatWorkflowName(workflow_full_path) {
            if (this.selectedAutomationType.name.toLowerCase() === AUTOMATION.DELINQUENCY_AUTOMATION.toLowerCase()) {
                const parts = workflow_full_path.split(' > ');
                const state = parts[0];
                const formattedState = this.stateListRawMixin.find(item => item.isoCode === state)?.name || state;
                return [formattedState, ...parts.slice(1)].join(' > ');
            }
            return workflow_full_path;
        }
    }
}
</script>

<style scoped></style>