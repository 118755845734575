<template>
    <div>
        <hb-modal size="medium" :title="title" v-model="dialog" confirmation @close="$emit('close')">
            <template v-slot:content>
                <div class="py-4 px-6">
                    Reverting back will switch to <strong>{{ type }}</strong> workflow. 
                    <br/><br/>
                    Are you sure to switch <strong>{{ type }}</strong> workflow?                   
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="$emit('ok')">Continue</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
export default {
    name: 'RevertBackModal',
    data() {
        return {};
    },
    created() {
    },
    props: ['title','type','value'],
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        
    },
    methods: {
    }
}
</script>

<style scoped></style>