<template>
    <hb-modal size="medium" :title="title" v-model="dialog" confirmation @close="$emit('close')">
        <template v-slot:content>
            <div class="py-4 px-6">
                <div class="sub_title mb-2" v-html="sub_title"></div>
                <div class="description" v-html="description"></div>
                <v-list v-if="actions?.length > 0 && flow_type == 'workflow'">
                    <v-list-item v-for="(a, i) in actions" :key="i" @click="$emit('applyActions', actions)"
                        style="min-height: 25px !important;" class="px-0">
                        <v-list-item-title class="d-flex align-center">
                            <HbCheckbox v-model="a.active" :label="a.name" class="pr-2" /> <span class="process">
                                <HbIcon :mdi-code="a.icon" small
                                    :color="a.icon_context" /> 
                                <span v-html="a.process_name"></span>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
                <hb-combobox class="py-4" v-else 
                    v-model="selectedProperties"
                    :items="filteredProperties"
                    v-validate="'required'"
                    id="properties"
                    name="properties"
                    data-vv-scope="default"
                    data-vv-as="Properties"
                    data-vv-name="properties"
                    label="Select Properties"
                    :error="errors.collect('default.properties').length > 0"
                    item-value="id"
                    item-text="name"
                    key="properties"
                    @change="$emit('applyActions', selectedProperties)"
                    return-object
                  >
                    <template v-slot:item="{item, attrs}">
                      <v-list-item-action> 
                        <HbIcon
                          :mdi-code="attrs.inputValue ? 'check_box' : 'mdi-checkbox-blank-outline'"
                          :color="attrs.inputValue ? '#00848E' : '#9b9b9b'"
                        />
                      </v-list-item-action>
                      <v-list-item-content> 
                          <v-list-item-title> 
                              <span>{{ item.number }} - {{item.name}}</span>
                          </v-list-item-title> 
                      </v-list-item-content> 
                    </template>
                  </hb-combobox>
            </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('ok')" :disabled="disabled" :loading="disabled">{{ AUTOMATION.APPLY
                }}</hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import AUTOMATION from '@/constants/automation.js';
import { mapGetters } from "vuex";
export default {
    name: 'ApplyWorkflowModal',
    data() {
        return {
            selectedProperties:[]
        };
    },
    async created() {
        this.AUTOMATION = AUTOMATION;        
    },
    computed: {
        ...mapGetters({
            properties: "propertiesStore/filtered"
        }),
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        filteredProperties() {
            const selectedIds = this.actions.map(sp => sp.id);
            return this.properties.filter(prop => !selectedIds.includes(prop.id));
        }
    },
    props: {
        title: {
            type: String,
            default() {
                return 'Apply';
            }
        },
        sub_title: {
            type: String,
            default() {
                return 'Automation > Type';
            }
        },
        description: {
            type: String,
            default() {
                return 'Select the workflow.';
            }
        },
        actions: {
            type: Array,
            default() {
                return [];
            }
        },
        value: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        flow_type: {
            type: String,
            default() {
                return '';
            }
        },
    },
    methods: {
    }
}
</script>

<style scoped>
.sub_title,
.process {
    font-weight: 500;
}
</style>